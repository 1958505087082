<template>
  <div class="backdrop-component">
    <div class="backdrop">
      <b-modal
        id="cookies"
        ref="cookies"
        hide-footer
        no-close-on-backdrop
      >
        <div slot="modal-header-close">
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="closeModal"
          >
            ×
          </button>
        </div>
        <div class="gdpr-modal-dialog">
          <div class="modal-body bg-img">
            <p class="text-cookie">
              <span v-t="{path: 'BACKDROP.BACKDROP_COOKIES_TEXT'}"/>
            </p>
            <img
              class="cookie-style-img"
              :src="require('src/assets/icons/cookie.svg')"
            >
          </div>
          <div class="modal-footer footer">
            <div class="gdpr-mr-auto">
              <!--  TODO: Translation into English -->
              <a
                href="http://www.protv.ro/confidentialitate"
                target="_blank"
                rel="nofollow"
                class="btn-about"
              >
                <span v-t="{path: 'BACKDROP.BACKDROP_COOKIES_CONFIDENT'}"/>
              </a>
              <a
                href="http://www.protv.ro/about-cookies"
                target="_blank"
                rel="nofollow"
                class="btn-about"
              >
                <span v-t="{path: 'BACKDROP.BACKDROP_COOKIES_COOKIES'}"/>
              </a>
            </div>
            <button
              class="btn btn-default btn-modal"
              type="button"
              data-dismiss="modal"
              @click="hideCoockieModal"
            >
              <span v-t="{path: 'BACKDROP.BACKDROP_COOKIES_AGREE'}"/>
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal
        id="adBlockModal"
        ref="adBlockModal"
        hide-footer
        lazy
        size="lg"
        busy
      >
        <div slot="modal-title">
          <span class="ml-3 d-inline-block">
            <span v-t="{path: 'BACKDROP.BACKDROP_TITLE'}"/>
          </span>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-9">
              <p>
                <span v-t="{path: 'BACKDROP.BACKDROP_TEXT_FIRST'}"/>
              </p>
              <p>
                <span v-t="{path: 'BACKDROP.BACKDROP_TEXT_SECOND'}"/>
              </p>
            </div>
            <div class="d-none d-lg-flex col-lg-3">
              <span class="far fa-ban ads-ban"/>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="position-relative">
      <slot/>
    </div>
  </div>
</template>

<script>
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import { memoryStore } from "vimmi-web-utils/cjs/memoryStore";

  // The same for elements
  let isShowedModal = true;

  export default {
    name: 'BackdropComponent',
    components: {
    },
    props: {
      contextUrl: {
        type: String,
        default: '',
      },
      background: {
        type: String,
        default: '',
      },
      area: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    data() {
      return {
        image: null,
        isOnlyOneShow: true,
        isFirstShow: isShowedModal,
      };
    },
    mounted() {
      this.loadBanners(this.area);
      setTimeout(this.checkCoockies, 200); // 2min for loading script
    },
    methods: {
      hideCoockieModal() {
        this.$refs.cookies.hide();
        localStore.set('popupShown', 'true');
      },

      checkCoockies() {
        let val = memoryStore.get('close_backdrop') || false;

        if (val) {
          memoryStore.set('close_backdrop', false);
          return;
        }
        if (!localStore.get('popupShown')) {
          this.$refs.cookies.show();
        }
      },


    },
  };
</script>

<style lang="scss">
	@import './backdrop';
</style>
