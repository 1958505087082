<template>
  <div class="no-events">
    <div
      v-if="title" 
      class="no-events__title"
    >
      {{ title }}
    </div>
    <div class="no-events__content-wrap">
      <div class="no-events__content">
        <img :src="require('src/assets/icons/icon-sad.png')" alt="">

        <h2>{{ header }}</h2>

        <p>{{ problemText }}</p>
        
        <p>{{ solution }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name:'SearchError',  
    props: {
      title: {
        type: String,
        default: '',
      },
      header: {
        type: String,
        default: '',
      },
      problemText: {
        type: String,
        default: '',
      },
      solution: {
        type: String,
        default: '',
      },
    },
  }
</script>
<style lang="scss" scoped>
  .no-events{
    height: calc(100vh - 273px) ;

    &__title{
      font-weight: bold;
      font-size: 30px;
      line-height: 37px;
      color: $main-black;
    }
  }

  .no-events__content-wrap{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 350px);
    
    .no-events__content{
      width: 467px;
      border: 1px solid $border-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;  
      padding: 40px;

        @media (max-width: 500px) {
            border: none;
          }
          
      img{
        margin-bottom: 23px;
      }

      h2 {
        color: $main-black;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
      }

      p{
        text-align: center;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 28px;
        color: $main-paragraph-color;
      }
    }
  }
</style>
