export default {
  slidesPerView: 5,
  spaceBetween: 15,
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    1580: {
      slidesPerView: 10,
    },
    1440: {
      slidesPerView: 9,
    },
    1024: {
      slidesPerView: 7,
    },
    1020: {
      slidesPerView: 5,
    },
    768: {
      slidesPerView: 5,
    },
    480: {
      slidesPerView: 4,
    },
    300: {
      slidesPerView: 3,
    },
  },
  preloadImages: false,
  // watchSlidesProgress: true,
  lazy: true,
};
