<template>
  <div class="channel-profile-component container-fluid" :class="{ visible: fullDescription }">
    <div class="container-fluid bg-block px-4 py-4">
      <div class="row channel-wrapper pl-3">
        <!-- IMAGE --------------------------------------- -->
        <div class="col channel-profile-log">
          <img :src="normalizeImg(poster, 'thumb', 'ED', 'ED')" :alt="area.title">
        </div>

        <!-- NAME, FOLLOWERS, DESC ------------------------ -->
        <div class="col profile">
          <div class="profile-name">
            <div>
              <div class="channel-profile-title">
                {{ area.creator.name }}
              </div>
              <div class="channel-profile-followers channel-followers">
                {{ area.creator.followers }} Followers
              </div>
            </div>

            <div
              class="d-none d-lg-flex col channel-profile-follow"
            >
              <follow-button :id="area.creator.id"  :store-id="(area.store && area.store.id)" :variant="'profileBtn'"/>

              <button
                class="profileBtn follow-btn-followed"
                @click="openPopup"
              >
                Share
              </button>

              <div class="yopto-my-wrap">
                <div class="yotpo bottomLine"
                     :data-yotpo-product-id="area.creator.id"
                />
              </div>
            </div>
          </div>

          <!-- FOR MOBILE ------------------------ -->
          <div class="d-flex d-lg-none channel-profile-follow">
            <follow-button :id="area.creator.id" :store-id="(area.store && area.store.id)" :variant="'profileBtn'"/>

            <button
              class="profileBtn follow-btn-followed"
              @click="openPopup"
            >
              Share
            </button>
          </div>

          <!-- DESC DESKTOP -->
          <div ref="descriptionWrapper" class="description d-none d-lg-flex">
            <div
              class="channel-profile-description"
              :class="{
                active: fullDescription,
                'with-gradient': !fullDescription && isSeeMore,
              }"
            >
              <div ref="descriptionText" v-html="area.creator.description"/>
            </div>

            <button
              v-if="isSeeMore"
              type="button"
              class="description-see-more"
              @click="setFullDescription"
            >
              {{ fullDescription ? 'See less' : 'See more' }}
            </button>

            <div
              class="badges"
              @click="badgeClickHandler($event)"
            >
              <badge
                v-for="(badge, name) in badges"
                :key="badge.id + name"
                :badge="badge"
                :title="name"
              />
            </div>
          </div>
        </div>

        <!-- DESC MOBILE ---------------------------------- -->
        <div
          ref="mobileDescriptionWrapper"
          class="col-12 description d-flex d-lg-none mt-4"
        >
          <div
            class="channel-profile-description"
            :class="{
              active: fullDescription,
              'with-gradient': !fullDescription && mobileIsSeeMore,
            }"
          >
            <div
              ref="mobileDescriptionText"
              v-html="area.creator.description"
            />
          </div>

          <button
            v-if="mobileIsSeeMore"
            type="button"
            class="description-see-more"
            @click="setFullDescription"
          >
            {{ fullDescription ? 'See less' : 'See more' }}
          </button>

          <div
            class="badges"
            @click="badgeClickHandler($event)"
          >
            <badge
              v-for="(badge, name) in badges"
              :key="badge.id + name"
              :badge="badge"
              :title="name"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { showAt, hideAt } from 'vue-breakpoints';
  import normalizeImg from '@/filters/normalizeImg';
  import  FollowButton  from "src/components/followButton/followButton";
  import Badge from "@/components/badge/badge";

  export default {
    name: 'ChannelProfile',
    components: {
      hideAt,
      showAt,
      FollowButton,
      Badge,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
      badges: {
        type: Object,
        default: null,
      },
    },
    data: () => {
      return {
        fullDescription: false,
        isSeeMore: false,
        mobileIsSeeMore: false,
        badgesClasses: ['badge', 'badge__image', 'badge__title', 'badge__title--chunk'],
      };
    },
    computed: {
      poster() {
        if (!this.area) {
          return null;
        }
        return this.area.creator.picture || this.area.backdrop;
      },
    },
    mounted() {
      window.addEventListener('resize', this.checkDescriptionHeight);
      this.checkDescriptionHeight();

      this.$bus.$emit('currentShopData', {
        title: this.area.creator.name,
        img: this.area.creator.picture,
        id: this.area.creator.provider_id,
      });

      setTimeout(() => {window.yotpo.refreshWidgets()}, 0);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.checkDescriptionHeight);
    },
    methods: {
      normalizeImg,
      checkDescriptionHeight() {
        const descriptionWrapperHeight =
          this.$refs.descriptionWrapper.clientHeight;
        const descriptionTextHeight = this.$refs.descriptionText.clientHeight;
        const mobileDescriptionWrapperHeight =
          this.$refs.mobileDescriptionWrapper.clientHeight;
        const mobileDescriptionTextHeight =
          this.$refs.mobileDescriptionText.clientHeight;

        this.isSeeMore = descriptionTextHeight > descriptionWrapperHeight;
        this.mobileIsSeeMore =
          mobileDescriptionTextHeight > mobileDescriptionWrapperHeight;
      },
      setFullDescription() {
        this.fullDescription = !this.fullDescription;
      },
      openPopup() {
        this.$bus.$emit('ToggleSharingDialog', {
          currentStoreLink: `${this.$location}/s/${this.area.creator.slug}`,
          currentStoreName: this.area.creator.name,
          item: this.area.creator,
        });
      },
      badgeClickHandler(e) {
        if(this.badgesClasses.some(i => e.target.classList.contains(i))) {
          this.$bus.$emit('openBadgesModal', this.badges);
        };
      },
    },
  };
</script>

<style lang="scss">
@import './channel-profile';
</style>
