var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoaded && !_vm.areasLive && _vm.isLiveRoute)?_c('search-error',{staticStyle:{"margin":"1.25rem 0 0 2rem"},attrs:{"title":'Live',"header":'No Results',"problem-text":'Sorry, there are no live shows to display.',"solution":'Please check back later.'}}):_c('pv-scroll',{staticClass:"h-100 mt-3",class:{ 'no-scroll': _vm.noScroll, 'with-live-area': _vm.currentLive },attrs:{"is-loaded":_vm.isLoaded,"is-areas-loading":_vm.isAreasLoading},on:{"bottom":_vm.bottom}},[_c('transition-group',{staticClass:"h-100 d-flex flex-column",attrs:{"name":"stagged-area","appear":true,"appear-active-class":"animated fadeInUp","enter-active-class":"animated fadeInUp","leave-active-class":"animated fadeOutDown","tag":"div"},on:{"after-appear":_vm.onTransitionLeave,"enter":_vm.onTransitionEnter,"leave":_vm.onTransitionLeave}},[(_vm.profile)?_c('channel-profile',{key:"channelProfile",staticClass:"area-block custom_channel_profile",attrs:{"area":_vm.profile,"badges":_vm.badges}}):_vm._e(),(_vm.profile)?_c('channel-event',{key:"currentLive",staticClass:"area-block custom_channel_event",attrs:{"area":_vm.currentLive}}):_vm._e(),_vm._l((_vm._areas),function(area,index){return _c('div',{key:("key" + index),class:area && area.itype,style:({ order: area.order }),on:{"mouseenter":function($event){return _vm.isTitle(area)}}},[(area.aclass === 'section_social')?_c('social-block',{staticClass:"area-block",attrs:{"area":area}}):(area.aclass === 'section_internal' && _vm.page !== 'article')?_c('segment-carousel',{attrs:{"area":area,"ordered":""},on:{"empty-segment":_vm.onSegmentEmpty}}):(
          area.itype === 'section_album' || area.itype === 'section_playlist'
        )?_c('audio-player',{staticClass:"area-block",attrs:{"album":area}}):(area.aclass === 'section_promo')?_c('promo',{staticClass:"area-block",attrs:{"area":area}}):(area.aclass === 'epg')?_c('live-epg',{staticClass:"area-block",attrs:{"area":area}}):(
          area.aclass === 'video_flow' ||
            (area.aclass === 'video' && _vm.page === 'video')
        )?_c('media-player',{staticClass:"area-block",attrs:{"area":area,"extended":_vm.page === 'video'}}):(area.aclass === 'video' && _vm.page === 'home')?_c('vod',{staticClass:"area-block",attrs:{"area":area}}):_vm._e(),(
          [
            'item_mov_vod',
            'item_mov_vod_shoprz',
            'item_mov_episode' ].includes(area.itype)
        )?_c('media-player',{staticClass:"area-block",attrs:{"area":area,"shop-layouts":area.creator && area.creator.id ? area.creator : null,"is-one":true}}):(_vm.isSegmentCarousel(area.itype))?_c('segment-carousel',{key:_vm.areaKey(area, index),attrs:{"area":area,"ordered":"","reversed":area.itype === 'section_season',"border":index !== _vm._areas.length - 1 && area.itype !== 'section_season'},on:{"empty-segment":_vm.onSegmentEmpty}}):(
          ['category_head', 'category_series_head'].includes(area.itype)
        )?_c('category-title',{staticClass:"area-block",attrs:{"area":area}}):(area.itype === 'item_live')?_c('live-epg',{staticClass:"area-block",attrs:{"area":area}}):(area.itype === 'banner_group' && _vm.page === 'home')?_c('banner-carousel-embla',{attrs:{"area":area,"is-show-player":_vm.page === 'home'},on:{"empty-segment":_vm.onSegmentEmpty},scopedSlots:_vm._u([{key:"mediaPlayer",fn:function(){return [(_vm.page === 'home')?_c('player-with-tabs',{staticClass:"h-100 w-100",attrs:{"tabs":[
              {
                id: 'live',
                title: 'Live',
                link: '/on_air/',
              },
              {
                id: 'vod',
                title: 'Videos',
                link: ("/get_section/" + _vm.videoSectionTabId + "/"),
              } ],"watch-lives":true,"shop-layouts":area.creator && area.creator.id ? area.creator : null}}):_vm._e()]},proxy:true}],null,true)}):(area.itype === 'banner_group')?_c('banner-carousel',{attrs:{"area":area},on:{"empty-segment":_vm.onSegmentEmpty}}):(area.itype === 'item_app')?_c('app-area',{staticClass:"area-block",attrs:{"area":area}}):(['section_link_directives'].includes(area.itype))?_c('directive-carousel',{staticClass:"area-block",attrs:{"area":area},on:{"empty-segment":_vm.onSegmentEmpty}}):_vm._e()],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }