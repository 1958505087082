<template>
  <div class="vod-component container-fluid">
    <div
      v-drag-scroll-fn="{enabled: !videoPlaying && !displayFullVideo,
                         scrollLeft: setPrevAsCurrent,
                         scrollRight: setNextAsCurrent}"
      class="vod-video"
      :class="{'vod-video-playing': displayFullVideo}"
    >
      <media-video
        v-if="currentItem.id !== undefined"
        class="vod-video-player"
        :event-identifier="eventIdentifier"
        :autoplay="true"
        vod
      />
      <div class="vod-video-data-scroll-area overlay"/>
      <div class="vod-video-data-wrapper">
        <div class="vod-video-data">
          <!-- <h3
            class="vod-video-programm"
            :title="currentItem.site"
          >{{ currentItem.site || 'PRO TV' }}</h3> -->
          <!-- <div class="line-hr vod-video-line-separator"/> -->

          <trim-el
            class="vod-video-title"
            :text="currentItem.title"
            tag="h3"
          />
          <trim-el
            class="vod-video-description d-none d-lg-block"
            :text="currentItem.description"
            tag="h5"
          />
          <!-- <h3 class="vod-video-title" :title="currentItem.title"
              v-ellipsisText="{content: currentItem.title , resize: true}"></h3>
          <h5 class="vod-video-description d-none d-lg-block" :title="currentItem.description"
          v-ellipsisText="{content: currentItem.description , resize: true}"></h5>-->
          <br>
          <!-- <pro-button
            class="vod-video-link-btn"
            :videoid="currentItem.id"
            :target="currentItem.page || btnConf.target || '/'"
            :text="btnConf.text"
            :background="btnConf.background"
            :color="btnConf.color"
            :opacity="0.5"
          /> -->
          <duration
            class="vod-video-duration d-none d-lg-block"
            :duration="currentItem.duration"
            :icon-play="false"
          />
        </div>
      </div>
      <!-- <left-arrow
        class="align-top-center vod-arrow-left d-none d-lg-block"
        :class="{ 'vod-arrow-hidden' : videoPlaying}"
        @click.native="setPrevAsCurrent()"
      />
      <right-arrow
        class="align-top-center vod-arrow-right d-none d-lg-block"
        :class="{ 'vod-arrow-hidden' : videoPlaying}"
        @click.native="setNextAsCurrent()"
      /> -->
    </div>
    <!-- <main-player-pagination
      :items="items"
      :current="currentItem"
      :event-identifier="eventIdentifier"
    />

    <main-related-carousel
      class="d-none d-lg-block"
      :event-identifier="eventIdentifier"
      :area="items"
      :current-play="currentItem"
    /> -->
  </div>
</template>

<script>
  import MediaVideo from '../video/video';
  import Duration from '../duration/duration';
  import MainRelatedCarousel from './main-related-carousel';
  import LeftArrow from 'src/components/carousel/carousel-arrow-left';
  import RightArrow from 'src/components/carousel/carousel-arrow-right';
  import MainPlayerPagination from './pagination';
  import Config from 'src/service/config';
  import TrimEl from '../../common/trim-el';
  import debounce from 'lodash/debounce';
  import { sessionStore } from "vimmi-web-utils/esm/sessionStore";

  export default {
    name: 'VOD',
    components: {
      MainRelatedCarousel,
      LeftArrow,
      RightArrow,
      MainPlayerPagination,
      MediaVideo,
      Duration,
      TrimEl,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        eventIdentifier: Math.ceil(Math.random() * 10000000),
        currentItem: {},
        autoplay: false,
        videoPlaying: false,
        displayFullVideo: false,
        carouselId: null,
        calcHeight: null,
        checkIfPlay: false,
        autoViewCatouselId: 0,
        AUTO_PLAY_CAROUSEL_TIME: 10 * 1000,
        isFistPlaying: true,
        currentPage: null,
        items: [],
      };
    },
    computed: {
      currentPosition() {
        let pos = this.items.findIndex(el => {
          return el.id === this.currentItem.id;
        });
        return pos;
      },
      btnConf() {
        return {};// this.area.buttons[0];
      },
      autoplayCarousel() {
        return this.area &&
          this.area.hasOwnProperty('grid') &&
          this.area.grid.split('_').length > 0
          ? this.area.grid.split('_')[3] === 'auto'
          : false;
      },
      setCurrent() {
        return debounce(this.setCurrentDebounced, 300, {
          leading: true,
          trailing: false,
        });
      },
    },

    created() {
      this.currentItem = this.area;//.items[0];
      this.isFistPlaying = true;
      this.$bus.$on(
        this.eventIdentifier + '_videoComponentReady',
        this.event_videoComponentReady,
      );
      Config.loaded(() => {
        this.AUTO_PLAY_CAROUSEL_TIME = Config.get('player.timeoutChangingItems');
      });
    },

    // TODO: Re-check  this.carouselId for updated from DEVELOP
    // Don`t remove this code, need for develop carousel with video.
    // updated() {
    //   this.$bus.$off(this.carouselId + '_isPrevClicked', this.setCurrentAsPosition);
    //   this.$bus.$off(this.carouselId + '_isNextClicked', this.setCurrentAsPosition);
    //   this.getCarouselId();
    //   this.$bus.$on(this.carouselId + '_isPrevClicked', this.setCurrentAsPosition);
    //   this.$bus.$on(this.carouselId + '_isNextClicked', this.setCurrentAsPosition);
    // },

    mounted() {
      window.addEventListener('popstate', this.eventPopstateChange);

      if (
        this.area.hasOwnProperty('genre_code') &&
        this.area.genre_code.length > 0
      ) {
        sessionStore.set('genre', this.area.genre_code[0]);
      } else {
        sessionStore.set('genre', 'none');
      }
      this.$bus.$on(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );
      this.$bus.$on(
        this.eventIdentifier + '_playerNextPlaying',
        this.event_playerClickNext,
      );
      this.$bus.$on(
        this.eventIdentifier + '_playerPrevPlaying',
        this.event_playerClickPrev,
      );
      this.$bus.$on(
        this.eventIdentifier + '_loadedMetadata',
        this.event_loadedMetadata,
      );
      this.$bus.$on(
        this.eventIdentifier + '_playerEnded',
        this.event_playerEnded,
      );

      this.$bus.$on(
        this.eventIdentifier + '_playerEnded',
        this.event_playerEnded,
      );

      this.$bus.$on(
        this.eventIdentifier + '_playerPlaying',
        this.event_playerPlaying,
      );

      this.$bus.$on(
        this.eventIdentifier + '_playerResume',
        this.event_playerResume,
      );
      this.$bus.$on(this.eventIdentifier + '_playerPlay', this.event_playerPlay);

      this.$bus.$on(
        this.eventIdentifier + '_playerPause',
        this.event_playerPause,
      );

      this.$bus.$on(
        this.eventIdentifier + '_playerError',
        this.event_playerError,
      );

      this.$bus.$on(
        this.eventIdentifier + '_togglePlayerLayout',
        this.event_togglePlayerLayout,
      );

      this.$bus.$on(this.eventIdentifier + '_setCurrent', this.setCurrent);

      this.getCarouselId();

      this.$bus.$on(
        this.carouselId + '_isPrevClicked',
        this.setCurrentAsPosition,
      );
      this.$bus.$on(
        this.carouselId + '_isNextClicked',
        this.setCurrentAsPosition,
      );

      this.$bus.$on(
        this.eventIdentifier + '_showNextAfterError',
        this.setNextAsCurrent,
      );
      this.$bus.$on(
        this.eventIdentifier + '_isVideoRestricted',
        this.event_playerError,
      );

      this.$bus.$on('changedUserState', this.onChangedUserState);
      //      this.$bus.$on('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$on('userSuccessLogOut', this.onUserSuccessLogOut);
    },

    beforeDestroy() {
      clearTimeout(this.autoViewCatouselId);
      window.removeEventListener('popstate', this.eventPopstateChange);

      this.$bus.$off(
        this.eventIdentifier + '_playerEnded',
        this.event_playerEnded,
      );
      this.$bus.$off(
        this.eventIdentifier + '_loadedMetadata',
        this.event_loadedMetadata,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerNextPlaying',
        this.event_playerClickNext,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerPrevPlaying',
        this.event_playerClickPrev,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );
      this.$bus.$off(this.eventIdentifier + 'с', this.event_playerPlay);
      this.$bus.$off(
        this.eventIdentifier + '_playerPause',
        this.event_playerPause,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerPlaying',
        this.event_playerPlaying,
      );
      this.$bus.$off(
        this.eventIdentifier + '_playerResume',
        this.event_playerResume,
      );

      this.$bus.$off(
        this.eventIdentifier + '_togglePlayerLayout',
        this.event_togglePlayerLayout,
      );
      this.$bus.$off(this.eventIdentifier + '_playerError');
      this.$bus.$off(this.eventIdentifier + '_setCurrent', this.setCurrent);
      this.$bus.$off(
        this.eventIdentifier + '_videoComponentReady',
        this.event_videoComponentReady,
      );
      this.$bus.$off(
        this.carouselId + '_isPrevClicked',
        this.setCurrentAsPosition,
      );
      this.$bus.$off(
        this.carouselId + '_isNextClicked',
        this.setCurrentAsPosition,
      );
      this.$bus.$off(
        this.eventIdentifier + '_showNextAfterError',
        this.setNextAsCurrent,
      );
      this.$bus.$off(this.eventIdentifier + '_isVideoRestricted');

      this.$bus.$off('changedUserState', this.onChangedUserState);
      //      this.$bus.$off('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$off('userSuccessLogOut', this.onUserSuccessLogOut);
    },
    methods: {
      onChangedUserState(data = {}){
        this.loadPrevNext();
      },
      event_togglePlayerLayout(data) {
        this.displayFullVideo = data.value;
        if (data.arrows !== null || data.arrows !== undefined) {
          this.videoPlaying = data.arrows;
        }
      },
      event_videoComponentReady() {
        this.$bus.$emit(this.eventIdentifier + '_setComponentPoster', {
          item: this.currentItem,
        });
      },
      event_playerClickNext(options) {
        this.setNextAsCurrent({ isAlreadyPlaying: true });
        this.$bus.$emit(this.carouselId + '_click', {
          pos: this.currentPosition,
          direction: 'next',
          isAlreadyPlaying: true,
        });
        this.displayFullVideo = true;
        this.autoplay = true;
        if (options.restricted) {
          this.videoPlaying = false;
        }
        // this.setCurrentPage(this.currentItem);
      },
      event_playerClickPrev(options) {
        this.setPrevAsCurrent({ isAlreadyPlaying: true });
        this.$bus.$emit(this.carouselId + '_click', {
          pos: this.currentPosition,
          direction: 'prev',
          isAlreadyPlaying: true,
        });
        this.displayFullVideo = true;
        this.autoplay = true;
        if (options.restricted) {
          this.videoPlaying = false;
        }
        // this.setCurrentPage(this.currentItem);
      },
      event_loadedMetadata() {
        this.loadPrevNext();
        if (this.autoplayCarousel && !this.videoPlaying && this.isFistPlaying) {
          this.autoViewCatouselId = setTimeout(
            this.setNextAsCurrent,
            this.AUTO_PLAY_CAROUSEL_TIME,
          );
        }
      },
      event_playerReady() {
        this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
          item: this.currentItem,
          autoplay: true,
          bigButton: true,
          mainPage: true,
          clickTime: Date.now(),
        });
      },
      event_playerPlay(data) {
        this.videoPlaying = true;
        this.displayFullVideo = true;
        this.autoplay = true;
        if (this.isFistPlaying) {
          this.setCurrentPage(this.currentItem);
        }
        this.isFistPlaying = false;
        clearTimeout(this.autoViewCatouselId);
        this.autoViewCatouselId = 0;
        // this.setCurrentPage(this.currentItem);
      },
      event_playerPlaying(data) {
        this.videoPlaying = true;
        this.displayFullVideo = true;
        this.autoplay = true;
        this.isFistPlaying = false;
        clearTimeout(this.autoViewCatouselId);
        this.autoViewCatouselId = 0;
      },
      event_playerResume(data) {
        this.videoPlaying = true;
        this.displayFullVideo = true;
        this.autoplay = true;
        this.isFistPlaying = false;
        clearTimeout(this.autoViewCatouselId);
        this.autoViewCatouselId = 0;
      },
      event_playerPause() {
        this.videoPlaying = false;
        this.autoplay = false;
        this.displayFullVideo = false;
      },
      event_playerEnded() {
        let nextItem = this.getNextItem();
        this.setCurrent(nextItem);
      },
      event_playerError(data) {
        this.loadPrevNext();
        this.displayFullVideo = true;
        this.videoPlaying = false;
        this.$logger.log(
          'MP',
          'event_playerError',
          this.videoPlaying,
          this.displayFullVideo,
        );
      },

      loadPrevNext() {
        let nextItem = this.getNextItem();
        let prevItem = this.getPrevItem();

        this.$bus.$emit(this.eventIdentifier + '_loadPrevNextVideo', {
          item: nextItem,
          isNext: true,
          autoplay: this.autoplay,
          bigButton: true,
          mainPage: true,
        });
        this.$bus.$emit(this.eventIdentifier + '_loadPrevNextVideo', {
          item: prevItem,
          isNext: false,
          autoplay: this.autoplay,
          bigButton: true,
          mainPage: true,
        });
      },
      getCarouselId() {
        let carousel = this.$el.querySelector('[component-id]');
        if (carousel) {
          this.carouselId = carousel.getAttribute('component-id');
        }
      },
      getNextItem() {
        let pos = this.currentPosition;
        return pos + 1 < this.items.length
          ? this.items[pos + 1]
          : this.items[0];
      },
      getPrevItem() {
        let pos = this.currentPosition;
        return pos - 1 >= 0
          ? this.items[pos - 1]
          : this.items[this.items.length - 1];
      },

      playVideo(item, isNew, autoplay, scrollDirection = 'next') {
        if (autoplay) {
          this.displayFullVideo = true;
        }
        if (isNew) {
          this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
            item: item,
            autoplay: autoplay,
            bigButton: true,
            mainPage: true,
            clickTime: Date.now(),
          });
          let pos = this.items.findIndex(el => {
            return el.id === item.id;
          });

          if (scrollDirection !== 'none') {
            this.$bus.$emit(this.carouselId + '_click', {
              pos: pos,
              direction: scrollDirection,
            });
          }
        } else {
          this.$bus.$emit(this.eventIdentifier + '_playVideo', {});
        }
      },
      /**
       * Set current items from positions
       * @param options -Object with propertys:
       * @param options.position - position of element
       * @param options.direction - direction for slide carousel
       */
      setCurrentAsPosition(options) {
        let pos = options.position;
        let direction = options.direction || 'next';
        let item =
          pos + 1 < this.items.length
            ? this.items[pos + 1]
            : this.items[0];
        this.setCurrent(item, direction);
      },
      setCurrentDebounced(item, direction = 'next', isAlreadyPlaying) {
        clearTimeout(this.autoViewCatouselId);
        if (item.id === this.currentItem.id) {
          return;
        }
        this.currentItem = item;
        this.displayFullVideo = false;
        if (!this.isFistPlaying) {
          this.setCurrentPage(this.currentItem);
        }
        if (!isAlreadyPlaying) {
          this.playVideo(item, true, this.autoplay, direction);
        }
      },

      setNextAsCurrent(options) {
        options = options || {};
        clearTimeout(this.autoViewCatouselId);
        this.setCurrent(this.getNextItem(), 'next', options.isAlreadyPlaying);
      },
      setPrevAsCurrent(options) {
        options = options || {};
        clearTimeout(this.autoViewCatouselId);
        this.setCurrent(this.getPrevItem(), 'prev', options.isAlreadyPlaying);
      },

      setCurrentPage(el) {
        this.$bus.$emit('setCurrentPage', el);
      },

      eventPopstateChange() {
        this.$nextTick(() => {
          let query = this.$router.currentRoute.query.video_id;
          if (query) {
            let item = this.items.find(el => {
              return el.page === query;
            });
            if (item) {
              this.setCurrent(item);
            }
          }
        });
      },
    },
  };
</script>

<style lang="scss">
	@import './vod';
</style>
