export default {
  slidesPerView: 4,
  spaceBetween: 20,
  scrollbar: {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    1580: {
      slidesPerView: 6,
    },
    1440: {
      slidesPerView: 5,
    },
    1024: {
      slidesPerView: 4,
    },
    // 1020: {
    //   spaceBetween: 16,
    // },
    576: {
      slidesPerView: 3,
    },
    400: {
      slidesPerView: 2,
    },
    300: {
      slidesPerView: 1,
      spaceBetween: 12,
    },
  },
  preloadImages: false,
//   watchSlidesProgress: true,
  lazy: true,
};
