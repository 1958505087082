<template>
  <transition
    name="Epg"
    appear-active-class="fadeIn"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div
      v-if="type=== 'component'"
      class="container-fluid epg-container animated"
    >
      <div class="row epg-content d-flex justify-content-center align-items-stretch">
        <div
          class="col-6 col-lg-4 epg-block order-2 order-lg-1"
        >
          <img
            class="epg-img"
            :src="require('src/assets/icons/icon_time.svg')"
          >
          <div class="epg-name word-wrap">
            <span v-t="{path: 'EPG.TV_SCHEDULE'}"/>
          </div>
        </div>
        <div
          v-if="isActiveItem"
          class="col-12 col-lg-4 epg-block epg-block-now order-1 order-lg-2"
        >
          <h3 class="epg-title">
            <span v-t="{path: 'EPG.NOW'}"/>
          </h3>
          <div class="row epg-timeline d-flex align-items-center flex-nowrap">
            <div class="epg-progress-start">
              {{ activeItem.startDate | formatDate('HH:mm') }}
            </div>
            <div class=" epg-progress ">
              <div
                class="epg-progress-value"
                :style="{ width: progress + '%', 'background-color': iconColor}"
              />
            </div>
            <div class="epg-progress-end">
              {{ activeItem.endDate | formatDate('HH:mm') }}
            </div>
          </div>
          <h4 class="epg-name word-wrap">
            {{ activeItem.title | stripHTML }}
          </h4>
        </div>
        <div
          v-if="isNextItem"
          class="col-6 col-lg-4 d-flex flex-column justify-content-center epg-block order-3"
        >
          <h3 class="epg-title">
            <span v-t="{path: 'EPG.NEXT'}"/> {{ nextItem.startDate | formatDate('HH:mm') }}
          </h3>
          <h4 class="epg-name word-wrap">
            {{ nextItem.title | stripHTML }}
          </h4>
        </div>
      </div>
    </div>
    <div
      v-else
      v-observe-visibility="changeHeightOfTitle"
      class="animated epg-block-container"
    >
      <div
        v-if="isAvailable"
        class="container-fluid"
      >
        <div class="row d-flex flex-column">
          <div
            v-if="isActiveItem"
            class="col-12 d-flex flex-column epg-block-active"
          >
            <h4
              ref="titleBlock"
              class="epg-block-name word-wrap"
              :style="{minHeight: heightOfTitle}"
            >
              {{ activeItem.title | stripHTML }}
            </h4>
            <div class="epg-block-progress">
              <div
                class="epg-block-progress-value"
                :style="{ width: progress + '%', 'background-color': iconColor}"
              />
            </div>
            <div class="row epg-block-timeline d-flex justify-content-between">
              <div class="epg-block-progress-start">
                {{ activeItem.startDate | formatDate('HH:mm') }}
              </div>
              <div class="epg-block-progress-end">
                {{ activeItem.endDate | formatDate('HH:mm') }}
              </div>
            </div>
          </div>
          <div
            v-if="isNextItem"
            class="col-12 d-flex flex-column epg-block-next"
          >
            <h3
              class="epg-block-title"
              :style="{'margin-top': nextTopMargin}"
            >
              {{ $t("EPG_LIST.UP_NEXT") }} {{ nextItem.startDate | formatDate('HH:mm') }}
            </h3>
            <h4 class="epg-block-name word-wrap">
              {{ nextItem.title | stripHTML }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { formatDate } from 'src/filters';
  import { floorTo } from 'src/utils';
  import Config from 'src/service/config';
  import { amsClient } from 'src/service/ams';

  export default {
    name: 'EPG',
    components: {
      // VideoLive
    },
    props: {
      area: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
      color: {
        type: String,
      },
      type: {
        type: String,
        default: 'block',
        validator: function(value) {
          let vals = ['component', 'block'];
          return vals.includes(value);
        },
      },
      parent: {
        type: String,
        default: 0,
        required: true,
      },
      minHeightTitle: {
        type: [String, Number],
        default: 'auto',
      },
    },
    data() {
      return {
        activeItem: null,
        nextItem: null,
        items: [],

        progress: 50,
        intervalProgress: 0,
        intervalActive: 0,

        heightOfTitle: 'auto',
        nextTopMargin: '0',

        lastDateResponse: 0,
        prevLastDateResponse: 0,

        INTERVAL_ACTIVE_UPDATE: 60 * 1000,
        INTERVAL_PROGRESS_UPDATE: 10 * 1000,
        TIME_TO_UPLOAD_NEW_ITEMS: 24 * 60 * 60 * 1000,
        TIME_TO_ROUNTED_EPG: 5 * 60,
      };
    },
    computed: {
      isAvailable() {
        return true; //return this.context && this.context.conf;
      },
      iconColor() {
        return this.color ? this.color : 'white';
      },
      isActiveItem() {
        return this.activeItem && this.activeItem.hasOwnProperty('title');
      },
      isNextItem() {
        return this.nextItem && this.nextItem.hasOwnProperty('title');
      },
    },

    watch: {
      minHeightTitle: {
        handler: function() {
          this.changeHeightOfTitle();
        },
        deep: false,
        immediate: true,
      },
    },
    created() {
      // Config.loaded(() => {
      //   this.TIME_TO_ROUNTED_EPG = Config.get(
      //     'user_profile_settings.EPG_CACHE_TIME',
      //   );
      // });
    },
    mounted() {
      this.items = Object.assign([], this.area.items);
      this.lastDateResponse =
        this.items.length > 0
          ? this.createDateAsLocal(this.items[this.items.length - 1].end)
          : new Date();
      this.prevLastDateResponse = new Date(0);
      this.getActiveItem();
      this.calculateProgress();

      this.intervalProgress = setInterval(
        this.calculateProgress,
        this.INTERVAL_PROGRESS_UPDATE,
      );
      this.intervalActive = setInterval(
        this.getActiveItem,
        this.INTERVAL_ACTIVE_UPDATE,
      );
    },
    destroyed() {
      clearInterval(this.intervalProgress);
      clearInterval(this.intervalActive);
    },
    methods: {
      formatDate,
      getActiveItem(checkActive = false) {
        let itemsLen = this.items.length;
        const now = Date.now(); // new Date(2018, 3, 6, 12, 35, 0);

        let activeEnd =
          this.activeItem && this.activeItem.hasOwnProperty('endDate')
            ? this.activeItem.endDate.getTime()
            : new Date();
        if (
          Math.abs(activeEnd - now) < this.INTERVAL_ACTIVE_UPDATE ||
          checkActive
        ) {
          this.activeItem = {};
          this.nextItem = {};
          for (let index = 0; index < itemsLen; index++) {
            let startTime = this.createDateAsLocal(this.items[index].start);
            let endTime = this.createDateAsLocal(this.items[index].end);

            if (startTime.getTime() < now && endTime.getTime() > now) {
              this.activeItem = this.items[index];
              this.activeItem.startDate = startTime;
              this.activeItem.endDate = endTime;
              if (index + 1 < itemsLen) {
                this.nextItem = this.items[index + 1];
                this.nextItem.startDate = this.createDateAsLocal(
                  this.items[index + 1].start,
                );
                this.nextItem.endDate = this.createDateAsLocal(
                  this.items[index + 1].end,
                );
              }
              break;
            } else if (
              startTime.getTime() > now &&
              endTime.getTime() < now + this.TIME_TO_UPLOAD_NEW_ITEMS
            ) {
              this.nextItem = this.items[index];
              this.nextItem.startDate = startTime;
              this.nextItem.endDate = endTime;

              break;
            }
          }
        }

        let isLastElementActive =
          this.activeItem && this.items.length > 0
            ? this.activeItem.id === this.items[this.items.length - 1].id
            : true;
        let isLastElementNext =
          this.nextItem && this.items.length > 0
            ? this.nextItem.id === this.items[this.items.length - 1].id
            : true;
        let isLessAsDay =
          Math.abs(this.lastDateResponse.getTime() - now) <=
          this.TIME_TO_UPLOAD_NEW_ITEMS;
        if (
          (isLastElementActive || isLastElementNext) &&
          this.lastDateResponse.getTime() > this.prevLastDateResponse.getTime() &&
          (isLessAsDay || this.items.length > 0)
        ) {
          this.getArea(this.lastDateResponse);
        }
      },
      getArea(start) {
        let date = start > 0 ? new Date(start) : new Date();
        let datetimeFrom = new Date(
          floorTo(date, this.TIME_TO_ROUNTED_EPG * 1000),
        );
        datetimeFrom.setSeconds(0);
        let datetimeTo = new Date(datetimeFrom.getTime() + 24 * 60 * 60 * 1000);

        let dateFrom = this.formatDate(datetimeFrom, 'yyyy-MM-dd');
        let dateTo = this.formatDate(datetimeTo, 'yyyy-MM-dd');
        let timeFrom = this.formatDate(datetimeFrom, 'HH:mm:ss');
        let timeTo = this.formatDate(datetimeTo, 'HH:mm:ss');

        let items = this.parent;
        this.items[0] && this.items[0].parent ? this.items[0].parent : 0;
        if (items) {
          let url = `/epg/?start_date=${dateFrom}&end_date=${dateTo}&start_time=${timeFrom}&end_time=${timeTo}&items=${items}`;
          amsClient.callAms(url, { cache: false }).then(data => {
            let itemsArr = Object.assign([], data.items);
            itemsArr.forEach(el => {
              el.start =
                el.start.length < 9 ? el.date + ' ' + el.start : el.start;
              el.end = el.end.length < 9 ? el.date_end + ' ' + el.end : el.end;
            });
            let responseLen = data.items.length;
            this.prevLastDateResponse = start > 0 ? start : datetimeFrom;
            this.lastDateResponse =
              responseLen > 0
                ? this.createDateAsLocal(data.items[responseLen - 1].end)
                : datetimeTo;
            itemsArr = itemsArr
              .concat(this.items)
              .filter(
                (el, index, self) =>
                  self.findIndex(cm => el.id === cm.id) === index,
              );
            itemsArr.sort((el1, el2) => {
              let startTime1 = this.createDateAsLocal(el1.start).getTime();
              let startTime2 = this.createDateAsLocal(el2.start).getTime();
              return startTime1 - startTime2;
            });
            this.items = Object.assign([], itemsArr);
            this.getActiveItem(true);
          });
        } else {
          this.prevLastDateResponse = start > 0 ? start : datetimeFrom;
          this.lastDateResponse = datetimeTo;
        }
      },
      calculateProgress() {
        if (this.isActiveItem) {
          let startTime = this.activeItem.startDate;
          let endTime = this.activeItem.endDate;
          let nowTime = new Date();
          this.progress = Math.round(
            ((nowTime - startTime) / (endTime - startTime)) * 100,
          );
        }
      },

      changeHeightOfTitle() {
        if (this.type !== 'component' && (this.isActiveItem || this.isNextItem)) {
          let pht = isNaN(parseInt(this.minHeightTitle))
            ? 'auto'
            : parseInt(this.minHeightTitle);
          if (this.isActiveItem) {
            let ht = this.$refs.titleBlock
              ? this.$refs.titleBlock.clientHeight
              : 0;

            if (this.minHeightTitle !== 'auto' && ht > 0) {
              this.heightOfTitle = Math.max(ht, pht) + 'px';
            } else if (this.minHeightTitle === 'auto' && ht > 0) {
              this.heightOfTitle = ht + 'px';
            } else {
              this.heightOfTitle = 'auto';
            }
            this.$emit('update:minHeightTitle', this.heightOfTitle);
            this.nextTopMargin = 0;
          } else {
            this.nextTopMargin =
              this.minHeightTitle !== 'auto' ? 53 + pht + 'px' : '75px';
          }
        }
      },

      createDateAsLocal(strdate) {
        let tz = -new Date().getTimezoneOffset();
        let normalize = number => (number < 10 ? '0' + number : number);
        let tzHours = normalize(Math.floor(Math.abs(tz) / 60));
        let tzMin = normalize(Math.floor(Math.abs(tz) % 60));
        let tzSing = tz >= 0 ? '+' : '-';
        strdate =
          strdate.replace(' ', 'T') +
          '.000Z'.replace('Z', `${tzSing}${tzHours}:${tzMin}`);
        let date = new Date(strdate);
        return date;
      },
    },
  };
</script>

<style lang="scss">
	@import './epg';
</style>
