<template>
  <div class="banner-item" @click="openVod">
    <div class="banner-preview">
      <video
        ref="video"
        playsinline
        muted
        preload="none"
        :class="{ 'is-vertical': isVertical }"
        :style="{ 'background-image': 'url(' + normalizeImg(image, 'thumb', 'FHD', 'HD') + ')' }"
      />
      <banner-video-item-info :link="item.link" />
    </div>
    <slot name="title" />
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import { amsClient } from '@/service/ams';
  import HlsVideoService from '@/service/hls-video';
  import BannerVideoItemInfo from './banner-video-item-info/banner-video-item-info';
  import normalizeImg from '@/filters/normalizeImg';
  import DeviceService from '@/service/device-service';
  import { memoryStore } from 'vimmi-web-utils/cjs/memoryStore';
  import { PLAYER_METHODS, SHOP_EVENTS, PM, SHOP_METHODS } from 'vimmi-web-utils/cjs/postMessage';

  const BannersHlsService = new HlsVideoService();

  export default {
    name: 'BannerVideoItem',
    components: {
      BannerVideoItemInfo,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      currentIndex: {
        type: Number,
        required: true,
      },
      ignoreClick: {
        type: Boolean,
        default: false,
      },
      isClickAllowed: {
        type: Function,
        default: () => true,
      },
    },
    data() {
      return {
        isVisible: false,
        isVertical: false,
        paused: false,
        visibilityOptions: {
          threshold: 1,
        },
      };
    },
    computed: {
      ...mapGetters({
        chachedPreviewMedia: 'chachedPreviewMedia/chachedPreviewMedia',
        playTemplates: 'chachedPreviewMedia/getPlayTemplate',
        screenType: 'popup/screenType',
      }),
      media() {
        return this.chachedPreviewMedia[this.item.id];
      },
      image() {
        return this.item?.banner_stb || this.item?.banner;
      },
      observer() {
        return new IntersectionObserver(this.checkVisibility, this.visibilityOptions);
      },
      isMiniature() {
        return this.screenType === 'miniature';
      },
      isVod() {
        return this.item?.itype?.includes('vod');
      },
      pageForCurrentLive() {
        return this.$store.getters['player/pageForCurrentLive'];
      },
      pageForSecondLive() {
        return this.$store.getters['player/pageForSecondLive'];
      },
    },
    watch: {
      currentIndex: {
        immediate: true,
        handler(currentIndex) {
          this.isVertical = false;
          this.handleVideoPreview(currentIndex);
        },
      },
    },
    mounted() {
      this.observer.observe(this.$refs.video);
    },
    beforeDestroy() {
      this.observer.unobserve(this.$refs.video);
    },
    methods: {
      ...mapActions({
        addPreviewMediaItem: 'chachedPreviewMedia/addPreviewMediaItem',
      }),
      normalizeImg: normalizeImg,
      async handleVideoPreview(currentIndex) {
        BannersHlsService.clear();

        if (!this.playTemplates) {
          return;
        }

        if (currentIndex === this.index && this.isVisible) {
          if (this.item.itype === 'item_mov_vod_shoprz') {
            if (!this.media) {
              const isVertical = !!this.item.media_is_vertical;
              let mediaUrl = null;

              if (this.item.recorded) {
                mediaUrl = this.item.media_url;
              } else if (this.item.mid) {
                mediaUrl = this.playTemplates?.info?.vod.replaceAll('{MID}', this.item.mid);
              }

              try {
                this.addPreviewMediaItem({ id: this.item.id, link: mediaUrl, isVertical });
              } catch {
              }
            }
          } else if (this.item.itype === 'item_epg_shop_event') {
            this.addPreviewMediaItem({ id: this.item.id, link: this.item.media });
          }

          if (!this.media) {
            return;
          }

          this.bindMediaPreview();
        } else if (this.paused) {
          this.paused = false;
          this.$emit('runAutoPlay');
        }
      },
      bindMediaPreview() {
        setTimeout(async () => {
          BannersHlsService.bindVideo(this.$refs.video, this.media.link);

          if (BannersHlsService.canPlay()) {
            const playing = await BannersHlsService.play(!DeviceService.isMobile());

            if (!playing) {
              return;
            }

            this.isVertical = this.media.isVertical;

            // Pause autoplay slider
            this.paused = true;
            this.$emit('pauseAutoPlay');

            BannersHlsService.onVideoEnded(() => {
              // Run autoplay slider

              this.paused = false;
              this.$emit('runAutoPlay');
            });
          }
        }, 100);
      },
      checkVisibility([intersectionObj]) {
        this.isVisible = intersectionObj.isIntersecting;
        this.handleVideoPreview(this.currentIndex);
      },
      async openVod() {
        if (!this.isClickAllowed()) {
          return;
        }
        BannersHlsService.clear();

        const item = await amsClient.callAms(this.item.link);

        const options = this.$stats.formatEventDate(this.item);

        // const itype = item.head.itype;
        // const options = {
        //   object_type: this.$stats.getItemType(this.item),
        //   position: this.index,
        //   channel_id: item.head?.store?.id || item.head?.creator?.id || (typeof item.head?.creator === 'string' ? item.head?.creator : undefined),
        // };
        // if (itype.includes('live') || itype.includes('vod') || itype.includes('epg_shop_event')) {
        //   options.media_type = itype.includes('vod') ? 'vod' : 'live';
        //   options.media_id = item.head.id;
        // } else if (itype === 'shop_product') {
        //   options.product_id = item.head.id;
        // } else {
        //   options.item_id = item.head.id;
        // }
        this.$stats.send('object_click', { ...options, position: this.index });


        const eventData = {
          chat_url: item.head.event.chat_url,
          chat_id: item.head.event.chat_id,
        };

        delete item.head.event;

        const liveId = memoryStore.get('live-player-identifier');

        if (this.isVod) {
          if (!this.pageForCurrentLive && !this.pageForSecondLive) {
            this.$bus.$emit('clearLiveFloating');
            this.$store.commit('player/SET_PAGE_FOR_CURRENT_LIVE', false);
            this.$store.commit('player/SET_PAGE_FOR_SECOND_LIVE', false);
          } else {
            this.$store.commit('dataAreasLive/setPlayingLive', false);
            this.$store.commit('player/CLEAR_EVENT_COMPONENT_STATE');

            PM.inst().target(liveId + '_video_player').send({
              type: PLAYER_METHODS.PAUSE,
            });
            PM.inst().target(liveId + '_video_player').send({
              type: SHOP_METHODS.CLOSE_FLOATING,
            });
          }

          if (this.isMiniature) {
            this.$store.commit('popup/setFloatingMode', 'vod');
          }
        }

        const vod = { ...item.head, ...eventData };

        this.$bus.$emit('togglePopupVOD', { item: vod });

      },
    },
  };
</script>
<style lang="scss" scoped></style>