<template>
  <transition
    name="epg"
    appear-active-class="fadeIn"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
  >
    <div class="animated live-epg-container">
      <div class="container-fluid px-0 px-lg-3">
        <video-live
          v-if="liveItem"
          :item="liveItem"
        />
      </div>
      <epg
        :area="area"
        type="component"
        :parent="parent"
      />
    </div>
  </transition>
</template>

<script>
  import VideoLive from 'src/components/live/live-player.vue';
  import Epg from 'src/components/epg/epg';
  import { amsClient } from 'src/service/ams';

  export default {
    name: 'LiveEpg',
    components: {
      VideoLive,
      Epg,
    },
    props: {
      area: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        liveItem: null,
      };
    },
    computed: {
      parent() {
        return (this.area && this.area.media) ? this.area.media.split('/')[2] : 0;
      },
    },
    mounted() {
      amsClient.callAms(this.area.link, { cache: false })
        .then((response) => {
          this.liveItem = response.data.head;
        })
        .catch((error) => {
          this.liveItem = this.area;
        });
    },
  };
</script>

<style lang="scss">
	@import './live-epg';
</style>
