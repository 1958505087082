<template>
  <div class="container-fluid">
    <div class="bg-block py-3 px-3 px-md-5 row mx-0">
      <div class="col-12 d-md-none">
        <h2 class="default-title responsive-title">
          {{ title }}
        </h2>
      </div>
      <div class="col-12 col-md-4">
        <responsive-image
          :src="img"
          :aspect-ratio="aspectRatio"
        />
      </div>
      <div class="col-12 col-md-8 d-flex flex-column justify-content-between">
        <div>
          <div class="d-none d-md-inline-block">
            <h2 class="default-title responsive-title">
              {{ title }}
            </h2>
          </div>
          <p class="d-block mt-3 mt-lg-5 text-muted">
            {{ description }}
          </p>
        </div>
        <p class="text-center text-md-left mb-0">
          <a
            class="btn btn-secondary"
            :href="area.dl_aos"
            :class="{'text-muted' : !area.dl_aos}"
            :disabled="!area.dl_aos"
          >
            <i class="fab fa-android"/>
            Android
          </a>
          <a
            class="btn btn-secondary"
            :class="{'text-muted' : !area.dl_ios}"
            :href="area.dl_ios"
            :disabled="!area.dl_ios"
          >
            <i class="fab fa-apple"/>
            IOS
          </a>
          <a
            class="btn btn-secondary"
            :class="{'text-muted' : !area.dl_aos_stb}"
            :href="area.dl_aos_stb"
            :disabled="!area.dl_aos_stb"
          >
            <i class="fas fa-tablet-alt"/> STB
          </a>
          <a
            class="btn btn-secondary"
            :class="{'text-muted' : !area.dl_web}"
            :href="area.dl_web"
            :disabled="!area.dl_web"
          >
            <i class="fas fa-browser"/> WEB
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import ResponsiveImage from 'src/components/reponsive-image';
  export default {
    name: 'AppArea',
    components: {
      ResponsiveImage,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
    },

    computed: {
      img() {
        return this.area.poster;
      },
      title() {
        return this.area.title;
      },
      description() {
        return this.area.description;
      },
      link() {
        return this.area.link;
      },
      aspectRatio() {
        return '16x9';
      },
    },
  };
</script>

<style lang="scss">
	.app-area-overlay {
		position: absolute;
		right: 0;
		top: 0;
	}
</style>
