<template>
  <div class="row col-12 video-live-wrapper px-0 mx-0">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <div class="col-12 col-lg-12 mb-3 mb-lg-0 px-0">
      <media-video
        class="media-player-video"
        :shop-layouts="shopLayouts"
        :event-identifier="eventIdentifier"
        :autoplay="autoplay"
      />
      <!--      <live-text-block-->
      <!--        v-if="activeChannel"-->
      <!--        class="mt-3"-->
      <!--        :event="currentEvent"-->
      <!--        :channel="activeChannel"-->
      <!--      />-->
      <!-- <div
        v-if="currentEvent"
        class="live-epg row"
      >
        <div class="live-epg-title col-md-9 col-sm-7">
          {{ eventTitle }}
        </div>
        <div class="live-epg-time col-md-3 col-sm-5">
          {{ currentEvent.startTime | formatDate('HH:mm', false) }} - {{ currentEvent.endTime |
          formatDate('HH:mm', false) }}
        </div>
        <div class="live-epg-synopsis col-12">
          {{ eventDescription }}
        </div>
      </div>-->
    </div>
    <!--    <channels-list-->
    <!--      v-if="channels.length"-->
    <!--      :event-identifier="eventIdentifier"-->
    <!--      :active-channel="activeChannelId"-->
    <!--      class="col-12 col-lg-4 px-0 pl-lg-4 pr-lg-0 mb-3 mb-lg-0"-->
    <!--      :items="channels"-->
    <!--    />-->
  </div>
</template>

<script>
  import EpgService from 'src/service/epg-service';
  import MediaVideo from 'src/components/player/video/video';
  import ChannelsList from 'src/components/channels-list/channels-list';
  import contentLanguage from 'src/filters/contentLanguage.js';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import LiveTextBlock from './live-text-block.vue';
  import { amsClient } from '../../service/ams';
  import { memoryStore } from "vimmi-web-utils/cjs/memoryStore";

  export default {
    name: 'LivePlayer',
    components: {
      ChannelsList,
      MediaVideo,
      LiveTextBlock,
    },
    props: {
      area: {
        type: Object,
        required: true,
      },
      shopLayouts: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        eventIdentifier: Math.ceil(Math.random() * 10000000),
        channels: [],
        activeChannelId: null,
        activeChannel: null,
        epgData: {},
        metadata: {},
        displayVideo: true,
        playerReady: false,
        autoplay: true,
      };
    },
    computed: {
      // currentEvent() {
      //   return this.activeChannelId &&
      //     this.epgData[this.activeChannelId] &&
      //     Array.isArray(this.epgData[this.activeChannelId]) &&
      //     this.epgData[this.activeChannelId].length
      //     ? this.epgData[this.activeChannelId][0]
      //     : null;
      // },
      currentEvent() {
        return this.area;
      },
      eventTitle() {
        return contentLanguage(this.currentEvent, 'title');
      },
      eventDescription() {
        return (
          contentLanguage(this.currentEvent, 'synopsis') ||
          contentLanguage(this.currentEvent, 'description')
        );
      },
    },
    mounted() {
      this.$bus.$on('changedUserState', this.onChangedUserState);
      //      this.$bus.$on('userSuccessAuth', this.onUserSuccessLogin);

      //      this.$bus.$on('userSuccessLogOut', this.onUserSuccessLogOut);

      this.$bus.$on(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );

      this.$bus.$on(
        this.eventIdentifier + '_setActiveChannel',
        this.event_setActiveChannel,
      );

      this.getChannels()
        .then(channels => {
          memoryStore.set('autoplay', true);
          this.channels = channels;
          let item = this.getSelectedItem(this.$route, this.channels);
          if (item) {
            this.activeChannelId = item.id;
            this.activeChannel = Object.assign({}, item);
            if (this.playerReady) {
              this.event_setActiveChannel(item, true);
            }
          }

          this.loadEpg()
            .then(epgData => {
              this.$nextTick(() => {
                if (Array.isArray(epgData) && epgData.length) {
                  this.epgData = EpgService.prepareEpg(epgData);
                  this.$bus.$emit(this.eventIdentifier + '_loadedEpg', this.epgData);
                }
              });
            });
        });
    },
    created() {
    },
    destroyed() {
      this.$bus.$off(
        this.eventIdentifier + '_playerReady',
        this.event_playerReady,
      );

      this.$bus.$off(
        this.eventIdentifier + '_setActiveChannel',
        this.event_setActiveChannel,
      );
      this.$bus.$off('changedUserState', this.onChangedUserState);
      //      this.$bus.$off('userSuccessAuth', this.onUserSuccessLogin);
      //      this.$bus.$off('userSuccessLogOut', this.onUserSuccessLogOut);
    },
    methods: {
      onChangedUserState(data = {}) {

        this.displayVideo = true;

        if (data.type === 'logged') {
          this.$bus.$emit(this.eventIdentifier + '_enableControls', {});
        }
      },
      //      onUserSuccessLogin() {
      //        this.$bus.$emit(this.eventIdentifier + '_enableControls', {});
      //        this.displayVideo = true;
      //      },
      //      onUserSuccessLogOut() {
      //        this.displayVideo = true;
      //      },
      event_playerReady() {
        this.$nextTick(() => {
          this.displayVideo = true; //authService.isAuth();
          this.playerReady = true;
          let item = this.getSelectedItem(this.$route, this.channels);
          if (item) {
            this.event_setActiveChannel(item, true);
          }
        });
      },
      event_setActiveChannel(channel, forcePlay) {
        this.$nextTick(() => {
          if (forcePlay || this.activeChannelId !== channel.id) {
            this.$bus.$emit(this.eventIdentifier + '_playNowVideo', {
              item: channel,
              autoplay: true,
              bigButton: true,
              clickTime: Date.now(),
            });
          }

          if (this.activeChannelId === channel.id) {
            return;
          }

          this.activeChannelId = channel.id;
          this.activeChannel = Object.assign({}, channel);

          let meta = {
            title: channel.title,
            description: channel.description || '',
          };
          this.metadata = normaliseMetaTags(meta);

          this.reloadComponent = false;
          this.$router.push({
            path: '/live',
            query: { vid: channel.id },
          });
        });
      },
      getSelectedItem(route, items) {
        let filteredItems = [];
        if (route.query && route.query.vid) {
          filteredItems = items.filter(el => {
            return el.id === route.query.vid;
          });
        }
        return !filteredItems.length ? items[0] : filteredItems[0];
      },
      loadEpg() {
        let channelsIds = this.channels.map(channel => {
          return channel.id;
        });
        let startDate = Date.now();
        let endDate = Date.now() + 5 * 60 * 1000;
        return EpgService.loadEpgByIds(channelsIds, startDate, endDate)
          .then(
            epgData => {
              return epgData || [];
            },
          );
      },
      getChannels() {
        // let url = '/get_channels/';
        return amsClient.callAms('/get_channels/', { cache: false })
          .then(data => {
            let err = data.error;
            if (err) {
              this.$nextTick(() => {
                if (typeof err === 'string') {
                  let prs = err.split(':');
                  if (prs[0] === '301') {
                    let url = prs[1] || '/';
                    let err = prs[2] || 'Content not found';

                    memoryStore.set('redirect', {
                      code: prs[0],
                      url: url,
                      err: err,
                    });
                    this.$router.push({ path: url });
                  }
                }
              });
              return [];
            }
            return data.items;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>

<style lang="scss" src="./_live-player.scss">
</style>
