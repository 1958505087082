<template>
  <div>
    <pro-carousel
      v-if="!loading"
      class="internal-section-component"
      :area="area"
      :items-prop="items"
    />
    <pro-carousel
      v-if="!loading && upcomingItems.length"
      class="internal-section-component space-top"
      :area="upcomingArea"
      :items-prop="upcomingItems"
    />
  </div>
</template>

<script>
  import ProCarousel from './pro-carousel';
  import ShoprzStore from 'src/store/shoprz';
  import { amsClient } from 'src/service/ams';
  import rtm from '../../service/realTimeMessages';
  import { debounce } from 'lodash/function';
  import { FollowMixin } from '../../plugins/mixin';
  export default {
    name: 'InternalSection',
    components: {
      ProCarousel,
    },
    mixins: [FollowMixin],
    props: {
      area: {
        type: Object,
        required: true,
      },
      ordered: {
        type: Boolean,
        required: false,
        default: false,
      },
      reversed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        loading: true,
        items: [],
        upcomingArea: {},
        upcomingItems: [],
        subscribers: [],
      };
    },
    computed: {
      isOnAir() {
        return this.area?.directives?.tag === 'on_air';
      },
    },
    mounted() {
      this.$bus.$on('changedUserState', this.getData);
      this.getData();
      if (this.isOnAir) {
        this.updateOnAir();
      }
    },
    beforeDestroy() {
      this.$bus.$off('changedUserState', this.getData);
      rtm.off(this.subscribers);
    },
    methods: {
      updateOnAir() {
        this.subscribers.push(rtm.on(
          ['finished', 'on_air_now', 'started', 'failed', 'scheduled'],
          debounce(() => this.getData(), 10000),
        ));
      },
      getData() {
        this.loading = true;
        amsClient.callAms(this.area?.link, { cache: false })
          .then(data => {
            this.loading = false;
            return this.isOnAir
              ? { ...data, items: this.remapOnAirItems(data.items, 7) }
              : data;
          })
          .then(data => {
            const ordered = this.checkOrdered(data);
            const reserveData = this.reversed ? ordered.reverse() : ordered;
            const area = this.area.items || [];
            this.items = [...area, ...reserveData];

            if (this.area?.page?.includes('Followed-stores')) {
              ShoprzStore.setFollowedStores([...this.items]);
            }
          })
          .catch(() => {
            this.loading = false;
            this.items = [];
            this.upcomingItems = [];
          });
      },
      checkOrdered(data) {
        return this.ordered
          ? Object.assign([], data.items || [])
          : Object.assign(
            [],
            data.items.sort(() => 0.5 - Math.random()) || [],
          );
      },
      remapOnAirItems(items, maxAirItems = 7) {
        let lives = [];
        let upcoming = [];

        items.forEach((item) => {
          const utcTime = Date.now() + (new Date().getTimezoneOffset() * 60 * 1000);
          const isLive = new Date(item.start).getTime() <= utcTime
            && utcTime <= new Date(item.end).getTime()
            && item.is_live;

          if (isLive) {
            lives = [...lives, item];
          } else {
            upcoming = [...upcoming, item];
          }
        });

        if (lives.length < maxAirItems) {
          return [...this.sortOnAirItems(lives), ...this.sortUpcomingItems(upcoming)];
        }

        this.upcomingItems = this.sortOnAirItems(upcoming);
        this.upcomingArea = { ...this.area, title: 'Coming soon' }

        return this.sortOnAirItems(lives);
      },

      sortOnAirItems(items) {
        return items
          .sort((a, b) =>
            this.isFollowed(b.creator.id) - this.isFollowed(a.creator.id) ||
            b.creator.followers - a.creator.followers)
      },
      sortUpcomingItems(items) {
        return items
          .sort((a, b) =>
            this.isFollowed(b.creator.id) - this.isFollowed(a.creator.id) ||
            new Date(a.event_start) - new Date(b.event_start))
      },
    },
  };
</script>

<style lang="scss" scoped>
  .loader-container {
    min-height: 200px;
  }

  .space-top {
    margin-top: 20px;

    @media (min-width: 1024px) {
      margin-top: 40px;
    }
  }
</style>
