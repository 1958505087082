<template>
  <div class="suggestions">
    <div class="suggestions-header">
      <div class="suggestions-store">
        <div class="store-avatar">
          <img
            :src="normalizeImg(poster, 'thumb', 'ED', 'ED')"
            :alt="event.title"
          />
        </div>
        <span class="store-name">{{ event.creator.name }}</span>
      </div>

      <follow-button :id="event ? event.creator.id : ''" :store-id="(event.store && event.store.id)" variant="follow-btn" />
    </div>

    <div class="suggestions-inner">
      <div v-if="loading">loading...</div>
      <div v-else>
        <template v-if="videos.length">
          <swiper-carousel
            :config="configs"
            :items="videos"
            hide-more-btn
            item-variant="secondary"
            class="carousel-videos"
            @click:item="handleStartVideo"
          />
        </template>
        <template v-else>
          <div style="height: 230px">
            Sorry, store doesn't have recommended videos
          </div>
        </template>
        <swiper-carousel
          :config="productCarouselConfig"
          :items="items.products"
          hide-more-btn
          class="carousel-products"
        >
          <template #item="{ item }">
            <suggestion-product-item :item="item" @click="handleCancel" />
          </template>
        </swiper-carousel>
      </div>
    </div>

    <div v-if="!loading" class="suggestions-footer">
      <button
        class="button primary cancel-button"
        style="max-width: 104px"
        @click="handleCancel"
      >
        Cancel
      </button>
      <button
        class="button secondary"
        style="max-width: 225px"
        @click="handleStartVideo(videos[0])"
      >
        <template v-if="!videos.length">
          Ooops, something went wrong...
        </template>
        <template v-else>
          <template v-if="timer">
            Next event starts in {{ timeout }} sec
          </template>
          <template v-else>Start next event</template>
        </template>
      </button>
    </div>
  </div>
</template>

<script>
  import FollowButton from '../../components/followButton/followButton.vue';
  import SwiperCarousel from '../../components/swiper-carousel/swiper-carousel.vue';
  import SuggestionProductItem from './suggestion-product-item.vue';

  import VIDEO_CAROUSEL_CONFIG from './carousel-configs/videos.conf';
  import PRODUCT_CAROUSEL_CONFIG from './carousel-configs/products.conf';
  import normalizeImg from '@/filters/normalizeImg';

  export default {
    name: 'Suggestions',
    components: {
      FollowButton,
      SuggestionProductItem,
      SwiperCarousel,
    },
    props: {
      event: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      items: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        configs: VIDEO_CAROUSEL_CONFIG,
        productCarouselConfig: PRODUCT_CAROUSEL_CONFIG,
        timer: null,
        timeout: 20,
      };
    },

    computed: {
      poster() {
        if (!this.event) {
          return null;
        }
        return this.event.creator.picture || this.event.backdrop;
      },

      videos() {
        if (!this.items.lives.length) {
          return this.items.videos;
        }

        return this.items.lives;
      },
    },

    watch: {
      loading(value) {
        if (!value) {
          this.countdown();
        }
      },
    },

    mounted() {
      this.countdown();
    },

    methods: {
      normalizeImg,

      handleStartVideo(video) {
        if (!this.videos.length) {
          return;
        }

        if (!this.items.lives.length) {
          this.startNewVod(video);
        } else {
          this.startNewEvent(video);
        }

        this.handleCancel();
      },

      handleCancel() {
        clearInterval(this.timer);

        this.timer = null;

        this.timeout = 20;
      },

      startNewEvent(video) {
        this.$router.push(`/s/${video.creator.slug}`);

        this.$bus.$emit('updateTitle', video.creator.name);
      },

      startNewVod(video) {
        this.$router.push(`/s/${video.creator.slug}`);

        this.$bus.$emit('togglePopupVOD', {
          item: {
            ...video,
            ...(!video?.share && {
              share: `/s/${video.creator.slug}/v/${video.slug}/`,
            }),
          },
        });

        this.$bus.$emit('headerHide');

        this.$store.commit('popup/setDocumentTitle', video.creator.name);
      },

      countdown() {
        this.timeout = 20;

        this.timer = setInterval(() => {
          if (this.timeout === 0) {
            this.handleStartVideo(this.videos[0]);
          } else {
            this.timeout--;
          }
        }, 1000);
      },
    },
  };
</script>
<style lang="scss">
  .suggestions {
    .carousel {
      &-videos,
      &-products {
        margin-bottom: 0 !important;

        .swiper-button-next {
          right: 0 !important;
        }
        .swiper-button-prev {
          left: 0 !important;
        }

        .swiper-scrollbar {
          left: 0%;
          background: transparent;
          width: 100%;

          .swiper-scrollbar-drag {
            background: #505f74;
            border-radius: 10px;
            cursor: pointer;
          }
        }
      }

      &-videos {
        .swiper-scrollbar {
          bottom: 40px;
        }
      }

      &-products {
        .swiper-scrollbar {
          bottom: -15px;
        }
        @media screen and (min-width: 475px) {
          margin-top: -12px;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .button {
    display: block;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    outline: none;
    max-width: 200px;

    @media screen and (min-width: 475px) {
      font-size: 15px;
    }

    &.primary {
      color: #333953;
      background-color: #ffffff;
    }

    &.secondary {
      color: #ffffff;
      background-color: #5090aa;
    }
  }
  .suggestions {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-image: url(./assets/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 14px;
    }

    &-inner {
      margin: 10px 0;
      flex: 1;
    }

    &-footer {
      margin-top: auto;
      display: flex;
      align-items: center;

      .cancel-button {
        margin-right: 14px;
      }
    }

    &-store {
      display: flex;
      align-items: center;
      column-gap: 9px;

      .store {
        &-name {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
        }

        &-avatar {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          background-color: black;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
</style>
