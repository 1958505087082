<template>
  <div
    ref="ugsVirtualScroll"
    class="ugc-virtual-scroll"
  >
    <slot />
    <div ref="scrollObserver" style="width: 100%; min-height: 1px; height:1px;">
      <loader :absolute="false" color="black" v-if="isAreasLoading || !isLoaded" />
    </div>
  </div>
</template>
<script>
  import { debounce } from 'lodash';
  import eventBus from '../../service/eventBus';
  import Loader from '@/components/loader/loader.vue';

  export default {
    name: 'UgcVirtualScroll',
    components: { Loader },
    props: {
      pageOffset: {
        type: Number,
        default: 50,
      },
      delayMs: {
        type: Number,
        default: 300,
      },
      isLoaded: {
        type: Boolean,
        default: true,
      },
      isAreasLoading: {
        type: Boolean,
        default: true,
      },
      isElement: {
        type: null,
        default: null,
      },
    },
    data() {
      return {};
    },

    computed: {

      scrollDebounce() {
        return debounce(this.scroll, this.delayMs, { leading: true });
      },
      element() {
        // this.logger.log(`Search element ${this.isElement}`);
        if (this.isElement) {
          return document.getElementById(this.isElement) || null;
        }
        // let el = document.getElementById(this.isElement);

        return null;
      },
    },

    mounted() {
      if (this.element) {
        this.element.addEventListener('scroll', this.scrollDebounce);
      } else {
        window.addEventListener('scroll', this.scrollDebounce);
      }
      window.addEventListener('resize', this.matchHeight);
      this.matchHeight();
    },
    beforeDestroy() {
      if (this.element) {
        // this.logger.log(`Remove scroll event from element`);
        this.element.removeEventListener('scroll', this.scrollDebounce);
      } else {
        // this.logger.log(`Remove scroll event from window`);
        window.removeEventListener('scroll', this.scrollDebounce);
      }
      window.removeEventListener('resize', this.matchHeight);
    },
    methods: {
      matchHeight() {
        let height = this.$refs.ugsVirtualScroll.clientHeight;
        eventBus.$emit('videopChangeHeight', height);
      },
      scroll(evt) {
        if (this.isLoaded && !this.isAreasLoading && this.isInViewport()) {
          this.eventBottom();
        }
      },

      isInViewport() {
        const rect = this.$refs.scrollObserver.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

        const visibleWidth = Math.min(rect.right, windowWidth) - Math.max(rect.left, 0);
        const visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
        const visibleArea = visibleWidth > 0 && visibleHeight > 0 ? visibleWidth * visibleHeight : 0;

        const totalArea = Math.floor(rect.width * rect.height);
        return visibleArea >= totalArea;
      },

      eventBottom() {
        // this.logger.log(`Emit bootom event`);
        this.$emit('bottom');
      },
    },
  };
</script>

<style lang="scss">
  .ugc-virtual-scroll {
    //min-height: calc(100vh + 100px);
    // overflow: hidden;
    // overflow-y: auto;
    // height: calc(100%);
    // margin: 0;
  }

  .loading-container {
    position: relative;
    height: 100%;
  }
</style>

