<template>
  <div class="suggestion-product" @click="handleBuy">
    <div class="suggestion-product--picture">
      <img
        v-if="image"
        class="swiper-lazy"
        :src="image | normalizeImg('thumb', 'SD', 'SD')"
      />
      <span v-else>Image is not set</span>
    </div>
    <div class="suggestion-product--info">
      <h4 class="suggestion-product--title">{{ title }}</h4>
      <div class="suggestion-product--prices">
        <span class="suggestion-product--price">
          {{ getFormattedCurrency(item.currency) || '$' }}
          {{ item.price }}
        </span>
        <span v-if="item.original_price" class="suggestion-product--price old">
          {{ getFormattedCurrency(item.currency) || '$' }}
          {{ item.original_price }}
        </span>
      </div>
      <button class="suggestion-product--button">Buy</button>
    </div>
  </div>
</template>

<script>
  import normalizeImg from 'src/filters/normalizeImg';

  import { getFormattedCurrency } from 'vimmi-web-utils/cjs/formatCurrency';
  export default {
    name: 'SuggestionProductItem',
    props: {
      item: {
        type: Object,
        default: () => ({}),
        required: true,
      },
    },

    computed: {
      image() {
        return (
          this.item?.picture ||
          this.item?.backdrop ||
          this.item?.thumbnail ||
          this.item?.poster ||
          this.item?.creator?.picture
        );
      },
      title() {
        return this.item?.itype === 'screen_shoprz'
          ? this.brand
          : this.item?.title || '';
      },
    },

    methods: {
      normalizeImg,
      getFormattedCurrency,
      handleBuy() {
        this.$emit('click');

        this.$bus.$emit('toggleProductPopup', {
          item: {
            ...this.item,
            link: this.item.link || this.item.checkout,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .suggestion-product {
    min-width: 94px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    color: #fff;
    cursor: pointer;

    &--picture {
      width: 100%;
      height: 100%;
      max-height: 145px;
      position: relative;
      overflow: hidden;
      margin-bottom: 8px;
      margin: 0 auto;

      img {
        object-fit: cover;
        width: 100%;
        height: 115px;
      }
    }

    &--info {
      padding: 12px;
    }

    &--title {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--prices {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      height: 49px;
      padding-bottom: 5px;
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.2);
    }

    &--price {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      &.old {
        color: #afaeaf;
        position: relative;
        width: fit-content;

        &::before {
          content: '';
          display: block;
          position: absolute;
          height: 2px;
          background-color: #f32626;
          top: calc(50% - 2px);
          width: 100%;
        }
      }
    }

    &--button {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #fff;
      outline: none;
      text-align: center;
      display: block;
      width: 100%;
    }
  }
</style>
