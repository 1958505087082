<template>
  <div class="container-fluid  d-lg-none">
    <div class="row  block-chanels ">
      <router-link
        v-for=" (link, index) in channelsList"
        :key="index"
        :to="link.link"
        class="block-chanels-item"
      >
        <img
          v-if="link.icon"
          :src="link.icon | normalizeImg('thumb')"
          :alt="link.title"
          width="auto"
          height="16px"
        >
        <span v-else>
          {{ link.title }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import Config from 'src/service/config';

  export default {
    name: 'BlockChanels',

    data() {
      return {
        channelsList: [],

      };

    },
    created() {
      Config.loaded(() => {
        this.channelsList = Config.get('context.header.selectors')[0].links;
      });
    },

  };

</script>

<style lang="scss">
	@import './block-chanels';
</style>
