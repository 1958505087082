<template>
  <div ref="emblaRef" class="embla h-100 w-100">
    <div class="embla--container h-100 w-100">
      <slot name="default" :currentIndex="activeSlide" :toggleAutoPlay="toggleAutoPlay"></slot>
    </div>
    <ul v-if="isShowDots" class="embla--pagination">
      <li
        v-for="(item, index) of slideNodes"
        :key="item.iid"
        :class="{ 'active': index === activeSlide }"
        @click="scrollTo(index)"
      />
    </ul>
  </div>
</template>

<script>
  import EmblaCarousel from 'embla-carousel';
  import EmblaAutoplayPlugin from 'embla-carousel-autoplay';

  export default {
    name: 'EmblaCarousel',
    props: {
      options: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        emblaApi: null,
        slideNodes: [],
        forceState: 'run',
        activeSlide: 0,
        isShowDots: false,
      };
    },
    computed: {
      colors() {
        return Array.from({ length: 20 }, (_, i) => {
          const hue = (i * 137.508) % 360;
          return `hsl(${hue}, 80%, 80%)`;
        });
      },
    },
    mounted() {
      const emblaRef = this.$refs.emblaRef;
      const options = { skipSnaps: true, ...this.options };
      const plugins = [];
      if (options.autoplay) {
        plugins.push(EmblaAutoplayPlugin({ ...options.autoplay }));
        delete options.autoplay;
      }
      if (options.dots) {
        this.isShowDots = true;
        delete options.dots;
      }

      this.emblaApi = EmblaCarousel(emblaRef, options, plugins);

      this.emblaApi
        .on('init', this.addDotBtnsWithClickHandlers)
        .on('reInit', this.addDotBtnsWithClickHandlers)
        .on('init', this.toggleDotBtnsActive)
        .on('reInit', this.toggleDotBtnsActive)
        .on('select', this.toggleDotBtnsActive)
        .on('autoplay:play', this.onAutoplayPlay)
        .on('autoplay:stop', this.onAutoplayPause)
        .on('slidesInView', this.toggleDotBtnsActive);
    },
    methods: {
      onAutoplayPlay(n) {
        /*if (this.forceState === 'pause') {
          this.toggleAutoPlay('pause');
        }*/
        console.trace('[carousel] autoplay:play', n);
        this.$emit('autoplay:play', n);
      },
      onAutoplayPause(n) {
        /*if (this.forceState === 'run') {
          this.toggleAutoPlay('run');
        }*/
        console.trace('[carousel] autoplay:pause', n);
        this.$emit('autoplay:pause', n);
      },
      scrollTo(n) {
        this.emblaApi?.scrollTo(n);
      },

      addDotBtnsWithClickHandlers() {
        this.slideNodes = this.emblaApi.slideNodes();
      },
      toggleDotBtnsActive(event) {
        this.activeSlide = this.emblaApi.selectedScrollSnap();
        if (this.forceState === 'run') {
          setTimeout(() => {
            if (this.emblaApi.plugins()?.autoplay && !this.emblaApi.plugins()?.autoplay?.isPlaying()) {
              if (this.forceState === 'run') {
                this.emblaApi.plugins().autoplay.stop();
              }

              setTimeout(() => {
                if (this.forceState === 'run') {
                  this.emblaApi.plugins().autoplay.play();
                }
              }, 200);
            }
          }, 200);
        }
      },
      toggleAutoPlay(mode) {
        console.log('[carousel] toggleAutoPlay', mode);
        const autoplay = this.emblaApi?.plugins()?.autoplay;
        if (!autoplay) return;
        this.forceState = mode;

        if (mode === 'pause') {
          autoplay.stop();
        } else if (mode === 'run') {
          if (!autoplay.isPlaying()) {
            autoplay.reset();
            autoplay.play();
          }
          /*const playOrStop = autoplay.isPlaying() ? autoplay.stop : autoplay.play;
          playOrStop();*/
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .embla {
    overflow: hidden;

    &--container {
      display: flex;
    }

    &--slide {
      height: 100%;
    }


    &--pagination {
      position: absolute;
      bottom: -24px;
      left: 50%;
      display: flex;
      gap: 8px;
      margin: 0;
      padding: 0;
      transform: translateX(-50%);

      @media (max-width: 1024px) {
        bottom: 18px;
        z-index: 2;
      }

      li {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #e2e5f1;
        cursor: pointer;

        &.active {
          background-color: $main-color;
        }
      }
    }
  }


</style>