<template src="./segment-carousel.html" />
<script>
  import { debounce } from 'lodash/function';
  import ShoprzStore from 'src/store/shoprz';
  import { FollowMixin } from 'src/plugins/mixin';
  import contentLanguage from 'src/filters/contentLanguage';
  import { amsClient } from 'src/service/ams';
  import rtm from 'src/service/realTimeMessages';
  import WishlistService from '@/store/wishlist';
  import SwiperCarousel from 'src/components/swiper-carousel/swiper-carousel';

  import {
    DEFAULT_CAROUSEL_CONFIG,
    CIRCLE_CAROUSEL_ITEMS_CONFIG,
    VERTICAL_CAROUSEL_ITEMS_CONFIG,
  } from 'src/config/carousel.config';

  export default {
    name: 'SegmentCarousel',
    components: {
      SwiperCarousel,
    },
    mixins: [FollowMixin],
    props: {
      area: {
        type: Object,
        required: true,
      },
      reversed: {
        type: Boolean,
        required: false,
        default: false,
      },
      ordered: {
        type: Boolean,
        required: false,
        default: false,
      },
      border: {
        type: Boolean,
        default: () => true,
      },
    },
    data: function() {
      return {
        loading: false,
        segmentItems: [],
        upcomingArea: {},
        upcomingItems: [],
        subscribers: [],
      };
    },
    computed: {
      isOnAirSegment() {
        return this.area?.directives?.tag === 'on_air';
      },
      isComingSoonSegment() {
        return this.area?.directives?.tag === 'coming_soon' && this.area.directives?.itype !== 'item';
      },
      isWishlist() {
        return this.area?.directives?.tag === 'wishlist';
      },
      isWatchLater() {
        return this.area?.directives?.tag === 'watch_later';
      },
      title() {
        return contentLanguage(this.area, 'title');
      },
      carouselConfig() {
        if (this.isWishlist) {
          return VERTICAL_CAROUSEL_ITEMS_CONFIG;
        }
        if (this.isWatchLater) {
          return DEFAULT_CAROUSEL_CONFIG;
        }

        switch (this.area?.item_image_shape) {
          case 'circle':
            return CIRCLE_CAROUSEL_ITEMS_CONFIG;
          case 'vertical':
            return VERTICAL_CAROUSEL_ITEMS_CONFIG;
          default:
            return DEFAULT_CAROUSEL_CONFIG;
        }
      },
      wishlistItems() {
        return WishlistService.getListArray();
      },
      wishlistVideosItems() {
        return WishlistService.getVideosListArray();
      },
      viewAllUrl() {
        if (this.area.id === 'all-events' || this.area.id === 'upcoming_events') {
          return `/get_section/all-events/?link=${encodeURIComponent(this.area.link)}&linkOnAir=${encodeURIComponent(this.area.linkOnAir)}&linkComingSoon=${encodeURIComponent(this.area.linkComingSoon)}&title=${this.area.title.includes('&') ? this.area.title.replaceAll('&', '^*^') : this.area?.title}`;
        }

        if (this.isWishlist) {
          return '/wishlist';
        }

        return this.area?.itype === 'section_filters_shoprz' && this.area.title != 'Uploads'
          ? '/stores'
          : `/get_section/${this.area.id}/?link=${encodeURIComponent(this.area.link)}&title=${this.area.title.includes('&') ? this.area.title.replaceAll('&', '^*^') : this.area?.title}`;
      },
    },
    watch: {
      wishlistItems(items) {
        if (!this.isWishlist) {
          return;
        }

        this.segmentItems = items;
      },
      wishlistVideosItems(items) {
        if (!this.isWatchLater) {
          return;
        }
        this.segmentItems = items;
      },
    },
    mounted() {
      this.$bus.$on('changedUserState', this.getSegmentItems);

      this.getSegmentItems();

      // ToDo deprecated remove use event in areas instead
      if (this.isComingSoonSegment) {
        this.updateEventsSegment();
        setTimeout(() => {
        }, 5000);
      }
    },
    beforeDestroy() {
      this.$bus.$off('changedUserState', this.getSegmentItems);
      rtm.off(this.subscribers);
    },
    methods: {
      // ToDo deprecated remove use event in areas instead
      updateEventsSegment() {
        this.subscribers.push(rtm.on(
          ['finished', 'on_air_now', 'started', 'failed', 'scheduled'],
          debounce((msg) => {
            if (msg.op === 'finished' && this.isComingSoonSegment) {
              return;
            }

            this.getSegmentItems();
          }, 1000),
        ));
      },
      getSegmentItems() {
        if (this.area.preparedItems) {
          this.segmentItems = this.area.preparedItems;
          if (!this.segmentItems.length) {
            this.$emit('empty-segment');
          }
          return;
        }
        if (this.isWishlist) {
          this.segmentItems = this.wishlistItems;
          if (!this.segmentItems.length) {
            this.$emit('empty-segment');
          }
          return;
        }

        if (this.isWatchLater) {
          this.segmentItems = this.wishlistVideosItems;
          if (!this.segmentItems.length) {
            this.$emit('empty-segment');
          }
          return;
        }

        this.loading = true;


        const needUseCacheTimestamp = this.isComingSoonSegment || this.isOnAirSegment;

        const link = needUseCacheTimestamp
          ? `${this.area.link}${this.$eventsTimestamp ? `&t=${this.$eventsTimestamp}` : ''}`
          : this.area?.link;
        // console.log('l', link);


        amsClient.callAms(link)
          .then(data => {
            this.loading = false;
            return this.isOnAir
              ? { ...data, items: this.remapOnAirItems(data.items, 7) }
              : data;
          })
          .then(data => {
            const ordered = this.checkOrdered(data);
            const reserveData = this.reversed ? ordered.reverse() : ordered;
            const area = this.area.items || [];

            this.segmentItems = [...area, ...reserveData];

            this.$bus.$emit('segmentIsNotEmpty', !!this.segmentItems.length);
            if (!this.segmentItems.length) {
              this.$emit('empty-segment');
            }

            if (this.isComingSoonSegment) {
              this.segmentItems = this.segmentItems.filter(i => !(i.is_live && i.on_air));
              this.$store.commit('dataAreasLive/setItems', { type: 'comingSoonItems', items: this.segmentItems });
            }

            if (this.area?.page?.includes('Followed-stores')) {
              ShoprzStore.setFollowedStores([...this.segmentItems]);
            }
            const type = this.isOnAirSegment ? 'airItems' : this.isComingSoonSegment ? 'comingSoonItems' : '';
            type && this.$store.commit('dataAreasLive/setItems', { type, items: this.segmentItems });

          })
          .catch(() => {
            this.loading = false;
            this.segmentItems = [];
            this.upcomingItems = [];
            this.$emit('empty-segment');
          });
      },
      checkOrdered(data) {
        return this.ordered
          ? Object.assign([], data.items || [])
          : Object.assign(
            [],
            data.items.sort(() => 0.5 - Math.random()) || [],
          );
      },
      remapOnAirItems(items, maxAirItems = 7) {
        let lives = [];
        let upcoming = [];
        items.forEach((item) => {
          const utcTime = Date.now() + (new Date().getTimezoneOffset() * 60 * 1000);
          const isLive = new Date(item.start).getTime() <= utcTime
            && utcTime <= new Date(item.end).getTime()
            && item.is_live;

          if (isLive) {
            lives = [...lives, item];
          } else {
            upcoming = [...upcoming, item];
          }
        });

        if (lives.length < maxAirItems) {
          return [...this.sortOnAirItems(lives), ...this.sortUpcomingItems(upcoming)];
        }

        this.upcomingItems = this.sortOnAirItems(upcoming);
        this.upcomingArea = { ...this.area, title: 'Coming soon' };

        return this.sortOnAirItems(lives);
      },

      sortOnAirItems(items) {
        return items
          .sort((a, b) =>
            this.isFollowed(b.creator.id) - this.isFollowed(a.creator.id) ||
            b.creator.followers - a.creator.followers);
      },
      sortUpcomingItems(items) {
        return items
          .sort((a, b) =>
            this.isFollowed(b.creator.id) - this.isFollowed(a.creator.id)); // new Date(a.event_start) - new Date(b.event_start)
      },
    },
  };
</script>
<style lang="scss" scoped>
  $segment-border-color: #e5e8f1;

  .segment {
    max-width: 100%;
    margin: 0 auto 30px;
    padding: 0 40px;
    overflow: hidden;

    @media (max-width: 420px) {
      padding: 0 20px;
    }

    &.with-border::after {
      content: '';
      display: block;
      height: 1px;
      margin-top: 30px;
      background-color: $segment-border-color;
    }
  }

  .segment-header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .segment-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: $main-text-color;
  }

  .segment-link {
    font-weight: 500;
    font-size: 14px;
    color: $main-color;

    &:hover {
      opacity: 0.6;
    }
  }

  @media (min-width: 1024px) {
    .segment {
      margin-bottom: 52px;
    }
  }
</style>
