<template>
  <ul class="pagination-list my-2">
    <li
      v-for="pos in itemsCounter"
      :key="pos"
      :tabindex="pos"
      class="pagination-item"
      :class="{'pagination-item-active': currentPosition === pos}"
      @click="setCurrent(pos)"
    />
  </ul>
</template>


<script>
  export default {
    name: 'Paginationplayer',
    props: {
      eventIdentifier: {
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      current: {
        type: Object,
        required: false,
      },
      position: {
        type: Number,
        required: false,
      },
      limit: {
        type: Number,
      },
    },
    computed: {
      itemsCounter() {
        return this.limit
          ? this.limit > this.items.length
            ? this.items.length
            : this.limit
          : this.items.length;
      },
      currentPosition() {
        let pos = this.position;
        return pos + 1; // pos from 0 in for from 1
      },
    },
    methods: {
      setCurrent(position) {
        // TODO: currentPosition - current position of item on array + 1
        this.$emit('update:position', position - 1);
      },
    },
  };
</script>

<style lang="scss" src="./_pagination.scss">
</style>
